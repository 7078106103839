import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { amber, lightBlue } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import "assets/css/index.css";
import React from "react";
import { Link } from "react-router-dom";
const LinkBehavior = React.forwardRef(({ href, ...props }, ref) => (
  <Link ref={ref} to={href} {...props} />
));

let theme = createTheme({
  palette: {
    background: {
      default: "#fcfcfc",
    },
    primary: {
      main: lightBlue[600],
    },
    secondary: {
      main: amber["A700"],
    },
    brands: {
      facebook: "#1877f2",
      whatsapp: "#075e54",
      instagram: "#c13584",
    },
  },
});

theme = createTheme(theme, {
  mixins: {
    brands: {
      facebook: {
        color: "#1877F2",
        background: "transparent",
      },
      whatsapp: {
        color: "#25D366",
        background: "transparent",
      },
      instagram: {
        color: "#ffffff",
        background:
          "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
        padding: theme.spacing(1 / 10),
        borderRadius: "16%",
      },
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        regular: {
          [theme.breakpoints.up("lg")]: {
            minHeight: "max(96px, 5vh)",
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});

export default theme;
