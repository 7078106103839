import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";

export default function LoadingPage() {
  return (
    <Stack width={1} height={1} justifyContent="center" alignItems="center">
      <Fade in style={{ transitionDelay: "900ms" }}>
        <CircularProgress size={128} />
      </Fade>
    </Stack>
  );
}
