/**
 * @typedef {import("axios").AxiosInstance} Client
 */

export const UserInputNodeTypes = {
  name: "User name",
  phone: "Phone number",
  email: "E-mail",
  location: "User location",
  custom: "Custom",
};

export const OrderStatus = {
  new: "New",
  hidden: "Hidden",
};

export const PaymentNodeMethods = {
  mbokDirect: "mBoK Direct Transfer",
  cash: "Cash on Delivery",
};

export default class PlatformApi {
  /**
   *
   * @param {Client} client
   * @returns {Promise<string>}
   */
  static async refreshToken(client) {
    const response = await client.request({
      method: "post",
      url: "/auth/token",
      withCredentials: true,
      _noauth: true,
      skipAuthRefresh: true,
    });
    return response.data.token;
  }

  /**
   *
   * @param {Client} client
   */
  constructor(client) {
    this._client = client;
  }

  /**
   *
   * @param {import("axios").AxiosRequestConfig} config
   */
  async _request(config) {
    const response = await this._client.request(config);
    return response.data;
  }

  /*
   * Authentication
   */

  /**
   * @param {string} username
   * @param {string} password
   */
  async signin(username, password) {
    const authResponse = await this._request({
      method: "post",
      url: "/auth/signin",
      data: {
        username,
        password,
      },
      withCredentials: true,
      _noauth: true,
      skipAuthRefresh: true,
    });
    return authResponse;
  }

  async signOut() {
    await this._request({
      method: "delete",
      url: "/auth/token",
      withCredentials: true,
      _noauth: true,
      skipAuthRefresh: true,
    });
  }

  /**
   *
   * @param {string} email
   */
  async checkRegistration(email) {
    const { exists } = await this._request({
      method: "post",
      url: "/auth/check",
      data: { email },
      _noauth: true,
      skipAuthRefresh: true,
    });
    return exists;
  }

  /**
   *
   * @param {object} data
   * @param {string} data.name
   * @param {string} data.organization
   * @param {string} data.email
   * @param {string} data.password
   */
  async signup({ name, organization, email, password }) {
    const authResponse = await this._request({
      method: "post",
      url: "/auth/signup",
      data: {
        name,
        organization,
        email,
        password,
      },
      withCredentials: true,
      _noauth: true,
      skipAuthRefresh: true,
    });
    return authResponse;
  }

  /*
   * all users
   */

  async getUsers() {
    const users = await this._request({ method: "get", url: "/user" });
    return users;
  }

  /*
   * Logged in user
   */

  async me() {
    const user = await this._request({ method: "get", url: "/user/me" });
    return user;
  }

  async updateMe(newInfo) {
    const user = await this._request({
      method: "post",
      url: "/user/me",
      data: newInfo,
    });
    return user;
  }

  async subscribeUser(userId, data) {
    const user = await this._request({
      method: "post",
      url: `/user/${userId}/subscription`,
      data,
    });
    return user;
  }

  /**
   * @param {object} authData
   * @param {string} authData.accessToken
   * @param {string} authData.userID
   */
  async connectFacebook({ accessToken, userID }) {
    const facebookData = await this._request({
      method: "post",
      url: "/user/me/facebook/connect",
      data: {
        accessToken,
        userID,
      },
    });
    return facebookData;
  }

  /**
   * @param {object} authData
   * @param {string} authData.accessToken
   * @param {string} authData.userID
   */
  async connectWhatsapp(userId, clientID, channelID) {
    const facebookData = await this._request({
      method: "post",
      url: "/user/wa",
      data: {
        userId,
        clientID,
        channelID,
      },
    });
    return facebookData;
  }

  /**
   * @param {object} authData
   * @param {string} authData.accessToken
   * @param {string} authData.userID
   */
  async connectInstagram({ accessToken, userID }) {
    const instagramData = await this._request({
      method: "post",
      url: "/user/me/instagram/connect",
      data: {
        accessToken,
        userID,
      },
    });
    return instagramData;
  }

  async disconnectFacebook() {
    await this._request({
      method: "post",
      url: "/user/me/facebook/disconnect",
    });
  }

  async disconnectInstagram() {
    await this._request({
      method: "post",
      url: "/user/me/instagram/disconnect",
    });
  }

  /*
   * Metrics
   */

  /**
   *
   * @param {"year"|"month"|"week"} interval
   */
  async getTrends(interval) {
    const { trends } = await this._request({
      method: "get",
      url: "/metric/trends",
      params: {
        interval,
      },
    });
    return trends;
  }

  /*
   * Plans
   */

  /**
   *
   */
  async getPlans() {
    const { plans } = await this._request({
      method: "get",
      url: "/plan",
    });
    return plans;
  }

  /**
   *
   */
  async addPlan(data) {
    const plan = await this._request({
      method: "post",
      url: "/plan",
      data,
    });
    return plan;
  }

  /*
   * Messaging
   */

  async getConversations() {
    const { conversations } = await this._request({
      method: "get",
      url: "/conversation",
    });
    return conversations;
  }

  async getConversationById(conversationId) {
    const conversation = await this._request({
      method: "get",
      url: `/conversation/${conversationId}`,
    });
    return conversation;
  }

  async toggleConversationStatus(conversationId) {
    const { conversation } = await this._request({
      method: "put",
      url: `/conversation/toggle/${conversationId}`,
    });
    return conversation;
  }

  async getMessages(conversationId) {
    const { messages } = await this._request({
      method: "get",
      url: `/message/${conversationId}`,
    });
    return messages;
  }

  async sendReply(conversationId, { text, images, audio, sender }) {
    const reply = await this._request({
      method: "post",
      url: `/message/${conversationId}`,
      data: {
        text,
        images,
        audio,
        sender,
      },
    });
    return reply;
  }

  /*
   * Bot Flow
   */

  async getAllElements() {
    const { elements } = await this._request({
      method: "get",
      url: "/element/",
    });
    return elements;
  }

  async getRoot() {
    const root = await this._request({ method: "get", url: "/element/root" });
    return root;
  }

  async getElementById(id) {
    const element = await this._request({
      method: "get",
      url: `/element/${id}/`,
    });
    return element;
  }

  async updateElement(id, updates) {
    const element = await this._request({
      method: "patch",
      url: `/element/${id}`,
      data: updates,
    });
    return element;
  }

  /**
   *
   * @param {string} id
   * @param {string} mediaType
   * @param {File} media
   */
  async updateElementMedia(id, mediaType, media) {
    const data = new FormData();
    data.append("mediaType", mediaType);
    data.append("media", media, media.name);
    const element = await this._request({
      method: "patch",
      url: `/element/${id}/media`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data,
    });
    return element;
  }

  async updateNodePosition(id, position) {
    const node = await this._request({
      method: "patch",
      url: `/element/${id}`,
      data: {
        position,
      },
    });
    return node;
  }

  async addNode({ type, position, data }) {
    const node = await this._request({
      method: "post",
      url: "/element",
      data: { type, position, data },
    });
    return node;
  }

  async addEdge(source, target) {
    const edge = await this._request({
      method: "post",
      url: "/element",
      data: { type: "edge", source, target },
    });
    return edge;
  }

  async disconnectNode(id) {
    const removedEdges = await this._request({
      method: "post",
      url: `/element/${id}/disconnect`,
    });
    return removedEdges;
  }

  async deleteElement(id) {
    await this._request({ method: "delete", url: `/element/${id}` });
  }

  async getAllExampleSets() {
    const { exampleSets } = await this._request({
      method: "get",
      url: "/example-set",
    });
    return exampleSets;
  }

  async getExampleSetById(id) {
    const exampleSet = await this._request({
      method: "get",
      url: `/example-set/${id}/`,
    });
    return exampleSet;
  }

  async getClients() {
    const clients = await this._request({
      method: "get",
      url: "/broadcast/users",
    });
    return clients;
  }

  async getClient(id) {
    const clients = await this._request({
      method: "get",
      url: `/broadcast/users/${id}`,
    });
    return clients;
  }

  /*
   * Categories
   */
  async addCategory(data) {
    const category = await this._request({
      method: "post",
      url: "/category",
      data,
    });
    return category;
  }

  async getAllCategories() {
    const { categories } = await this._request({
      method: "get",
      url: "/category",
    });
    return categories;
  }

  async getRootCategories() {
    const { categories } = await this._request({
      method: "get",
      url: "/category/roots",
    });
    return categories;
  }

  async getCategoryById(id) {
    const category = await this._request({
      method: "get",
      url: `/category/${id}`,
    });
    return category;
  }

  async getCategoryName(id) {
    const category = await this._request({
      method: "get",
      url: `/category/name/${id}`,
    });
    return category;
  }

  async editCategory(id, data) {
    const category = await this._request({
      method: "put",
      url: `/category/${id}`,
      data,
    });
    return category;
  }

  async deleteCategory(id) {
    await this._request({ method: "delete", url: `/category/${id}` });
  }

  /*
   * Products
   */
  async addProduct(data) {
    const product = await this._request({
      method: "post",
      url: "/product",
      data,
    });
    return product;
  }

  async addService(data) {
    const service = await this._request({
      method: "post",
      url: "/service",
      data,
    });
    return service;
  }

  async getCategoryProducts(id) {
    const products = await this._request({
      method: "get",
      url: `/product/cat/${id}`,
    });
    return products;
  }

  async getCategoryServices(id) {
    const services = await this._request({
      method: "get",
      url: `/service/cat/${id}`,
    });
    return services;
  }

  async getProductById(id) {
    const product = await this._request({
      method: "get",
      url: `/product/${id}`,
    });
    return product;
  }

  async getServiceById(id) {
    const service = await this._request({
      method: "get",
      url: `/service/${id}`,
    });
    return service;
  }

  async editProduct(id, data) {
    const product = await this._request({
      method: "put",
      url: `/product/${id}`,
      data,
    });
    return product;
  }

  async editService(id, data) {
    const service = await this._request({
      method: "put",
      url: `/service/${id}`,
      data,
    });
    return service;
  }
  async deleteProduct(id) {
    await this._request({
      method: "delete",
      url: `/product/${id}`,
    });
  }

  async deleteService(id) {
    await this._request({
      method: "delete",
      url: `/service/${id}`,
    });
  }

  /*
   * Orders
   */
  async getOrders(status) {
    const { orders } = await this._request({
      method: "get",
      url: "/order",
      params: { status },
    });
    return orders;
  }

  async getOrderById(orderId) {
    const order = await this._request({
      method: "get",
      url: `order/${orderId}`,
    });
    return order;
  }

  /**
   *
   * @param {string} id
   */
  async toggleHideOrder(id) {
    const order = await this._request({
      method: "post",
      url: `/order/${id}/toggle-hide`,
    });
    return order;
  }

  /**
   *
   * @param {string} id
   */
  async confirmOrder(id) {
    const order = await this._request({
      method: "post",
      url: `/order/${id}/confirm`,
    });
    return order;
  }

  /**
   *
   * @param {string} id
   */
  async rejectOrder(id) {
    const order = await this._request({
      method: "post",
      url: `/order/${id}/reject`,
    });
    return order;
  }
}
