import { Ability, AbilityBuilder } from "@casl/ability";
import { useAuthUser } from "providers/Auth.js";
import React, { useContext, useMemo } from "react";

const Context = React.createContext();

const permissions = {
  admin(_user, { can }) {
    can("manage", "all");
  },
  user(user, { can }) {
    if (user.subscription) {
      user.subscription.plan.features.forEach((feature) => {
        can("manage", feature);
      });
    }
  },
  anonymous(_builder) {},
};

function defineAbilityFor(user) {
  const builder = new AbilityBuilder(Ability);
  if (user?.role != null && typeof permissions[user.role] === "function") {
    permissions[user.role](user, builder);
  } else {
    permissions.anonymous(builder);
  }
  return builder.build();
}

export function useAbility() {
  const context = useContext(Context);
  return context;
}

function AbilityProvider(props) {
  const { user } = useAuthUser();
  const ability = useMemo(() => defineAbilityFor(user), [user]);
  return <Context.Provider value={ability} {...props} />;
}

export default AbilityProvider;
