import { useAuthState } from "providers/Auth.js";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function RequireAuth({ fallback, children }) {
  const navigate = useNavigate();
  const { initialized, isLoggedIn } = useAuthState();
  const show = initialized && isLoggedIn;
  useEffect(() => {
    if (initialized && !isLoggedIn) {
      navigate("/auth/login", { replace: true });
    }
  }, [initialized, isLoggedIn, navigate]);
  return show ? children : fallback;
}

export default RequireAuth;
