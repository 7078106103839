import PlatformApi from "api/platform.js";
import axios from "axios";
import refreshInterceptor from "axios-auth-refresh";
import PropTypes from "prop-types";
import { useAuthState } from "providers/Auth.js";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

/**
 * @callback GetToken
 * @returns {string} the current api token
 */

/**
 * @typedef {Object} PlatformApiContext
 * @property {URL} baseUri
 * @property {GetToken} getToken
 * @property {import("axios").AxiosInstance} client
 * @property {PlatformApi} api
 */

const Context = React.createContext();

/**
 * @returns {PlatformApiContext}
 */
export function usePlatformApiContext() {
  const context = useContext(Context);
  if (context == null) {
    throw new Error(
      "usePlatformApiContext hooks must be used within a PlatformApiProvider"
    );
  }
  return context;
}

export function usePlatformApi() {
  const { api } = usePlatformApiContext();
  return api;
}

function PlatformApiProvider({ baseUri, ...props }) {
  const authState = useAuthState();
  const login = useRef(authState.login);
  const logout = useRef(authState.logout);
  const tokenRef = useRef();
  const [token, setToken] = useState();

  const updateToken = useCallback((value) => {
    tokenRef.current = value;
    setToken(value);
  }, []);

  const url = useMemo(
    () => new URL(baseUri, window.location.origin),
    [baseUri]
  );
  const client = useMemo(() => {
    const client = axios.create({ baseURL: url.href });

    client.interceptors.request.use((config) => {
      if (tokenRef.current != null && !config._noauth) {
        config.headers["Authorization"] = `Bearer ${tokenRef.current}`;
      }
      return config;
    });

    refreshInterceptor(client, async () => {
      try {
        const newToken = await PlatformApi.refreshToken(
          axios.create({ baseURL: url.href })
        );
        tokenRef.current = newToken;
        setToken(newToken);
      } catch (err) {
        if (err.response?.status !== 401) {
          throw err;
        }
        logout.current();
      }
    });

    return client;
  }, [url.href]);

  const api = useMemo(() => new PlatformApi(client), [client]);

  useEffect(() => {
    async function getUser() {
      try {
        const user = await api.me();
        if (user.role === "admin") {
          login.current(user);
        } else {
          logout.current();
        }
      } catch (err) {
        logout.current();
      }
    }
    getUser();
  }, [api]);

  const value = useMemo(
    () => ({
      baseUri: url,
      token,
      setToken: updateToken,
      client,
      api,
    }),
    [url, token, updateToken, client, api]
  );
  return <Context.Provider value={value} {...props} />;
}

PlatformApiProvider.defaultProps = {
  noAuth: false,
};

PlatformApiProvider.propTypes = {
  noAuth: PropTypes.bool.isRequired,
};

export default PlatformApiProvider;
