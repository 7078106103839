import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import "assets/css/index.css";
import RequireAuth from "components/RequireAuth.js";
import AbilityProvider from "providers/Ability.js";
import AuthProvider from "providers/Auth.js";
import PlatformApiProvider from "providers/PlatformApi.js";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoadingPage from "views/LoadingPage.js";
import theme from "./theme.js";

const Admin = React.lazy(() => import("layouts/Admin.js"));
const Auth = React.lazy(() => import("layouts/Auth.js"));

function App() {
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <AuthProvider>
            <AbilityProvider>
              <PlatformApiProvider baseUri={process.env.REACT_APP_PLATFORM_URI}>
                <React.Suspense fallback={<LoadingPage />}>
                  <Routes>
                    <Route
                      path="/admin/*"
                      element={
                        <RequireAuth fallback={<LoadingPage />}>
                          <Admin />
                        </RequireAuth>
                      }
                    />
                    <Route path="/auth/*" element={<Auth />} />
                    <Route
                      path="*"
                      element={<Navigate replace to="/admin" />}
                    />
                  </Routes>
                </React.Suspense>
              </PlatformApiProvider>
            </AbilityProvider>
          </AuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
