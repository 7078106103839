import { useRollbar } from "@rollbar/react";
import React, { useContext, useEffect, useMemo, useReducer } from "react";

const Context = React.createContext();

function useAuthContext() {
  const context = useContext(Context);
  if (context == null) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return context;
}

export function useAuthState() {
  const [state, dispatch] = useAuthContext();
  const initialized = useMemo(() => state.init, [state.init]);
  const isLoggedIn = useMemo(() => state.user != null, [state.user]);
  const login = (user) => dispatch({ type: "login", user });
  const logout = () => dispatch({ type: "logout" });
  return {
    initialized,
    isLoggedIn,
    login,
    logout,
  };
}

export function useAuthUser() {
  const [state, dispatch] = useAuthContext();
  const user = useMemo(() => state.user, [state.user]);
  const update = (user) => dispatch({ type: "update", user });
  const logout = () => dispatch({ type: "logout" });
  return { user, update, logout };
}

function authReducer(state, action) {
  switch (action.type) {
    case "login": {
      return { init: true, user: action.user };
    }
    case "logout": {
      return { init: true, user: null };
    }
    case "update": {
      return {
        ...state,
        user: Object.assign({}, state.user, action.user),
      };
    }
    default: {
      throw new Error(`Unrecognized action type: ${action.type}`);
    }
  }
}

function AuthProvider(props) {
  const rollbar = useRollbar();
  const [state, dispatch] = useReducer(authReducer, {
    init: false,
    user: null,
  });

  useEffect(() => {
    const user = state.user;
    rollbar.configure({
      payload: {
        person:
          user == null
            ? undefined
            : {
                id: user._id,
                email: user.email,
              },
      },
    });
  }, [rollbar, state.user]);

  return <Context.Provider value={[state, dispatch]} {...props} />;
}

export default AuthProvider;
