import * as Rollbar from "@rollbar/react";
import ReactDOM from "react-dom";
import App from "./App.js";

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  environment: process.env.NODE_ENV,
  captureEmail: true,
};

ReactDOM.render(
  <Rollbar.Provider config={rollbarConfig}>
    <Rollbar.ErrorBoundary>
      <App />
    </Rollbar.ErrorBoundary>
  </Rollbar.Provider>,
  document.getElementById("root")
);
